import React, { useEffect } from 'react'
import * as Sentry from '@sentry/browser'
import YSAnalytics from '@yousiciangit/ys-analytics'
import Layout from './components/Layout'
import Tuner from './components/Tuner'
import HeroImage from './components/HeroImage'
import NumberStats from './components/NumberStats'
import ImageTextPhone from './components/ImageTextPhone'
import ImageTextPhoneReversed from './components/ImageTextPhoneReversed'
import ImageTextPick from './components/ImageTextPick'
import Slider from './components/Slider'
import RepeaterPicks from './components/RepeaterPicks'
import Blog from './components/Blog'
import DownloadLinks from './components/DownloadLinks'
import ImageTextYousician from './components/ImageTextYousician'
import PickImageLinks from './components/PickImageLinks'
import { fixAspectRatios } from './helpers/fixAspectRatios'

import CONFIG, { APPLICATION_NAME } from './config/current'

if (CONFIG.env === 'prod') {
    Sentry.init({
        dsn:
            'https://be52674dfebdf66ed654f8d24930e64d@sentry.yousician.net/202',
        environment: 'prod',
    })
}

function App() {
    useEffect(() => {
        window.ysAnalytics = new YSAnalytics({
            channel: 'yousician-landing',
            baseUrl: CONFIG.apiAddress,
            xApplicationName: APPLICATION_NAME,
        })
    })

    useEffect(() => {
        window.ysAnalytics.trackPageVisit(window.location.pathname)
    }, [window.location.pathname])

    useEffect(() => {
        window.ysAnalytics.trackPageScroll()
    }, [])

    useEffect(() => {
        if (typeof ResizeObserver !== 'undefined') {
            const resizeObserver = new ResizeObserver(() => {
                fixAspectRatios()
            })
            resizeObserver.observe(document.body)
        } else {
            window.addEventListener('resize', fixAspectRatios)
            return () => {
                window.removeEventListener('resize', fixAspectRatios)
            }
        }
    }, [])

    return (
        <Layout>
            <Tuner />
            <HeroImage />
            <NumberStats />
            <ImageTextPhone />
            <ImageTextPhoneReversed />
            <ImageTextPick />
            <Slider />
            <DownloadLinks />
            <RepeaterPicks />
            <Blog />
            <ImageTextYousician />
            <PickImageLinks />
        </Layout>
    )
}

export default App
