import CONFIG, { APPLICATION_NAME } from '../config/current'

const storageId = 'authenticated'

export async function checkIfLoggedIn() {
    try {
        const response = await fetch(`${CONFIG.apiAddress}/logged`, {
            method: 'GET',
            headers: {
                'X-Application-Name': APPLICATION_NAME,
            },
            credentials: 'include',
        })

        if (!response.ok) {
            throw new Error(`Error: ${response.status}`)
        }

        const data = await response.json()
        const authenticated = data?.authenticated === true
        localStorage.setItem(storageId, authenticated)
        return authenticated
    } catch (error) {
        console.error('Login error:', error)
        return false
    }
}

export function getIsAuthenticated() {
    return localStorage.getItem(storageId) === 'true'
}
